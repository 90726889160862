import commonStyles from '../sass/common.scss';

$(document).ready(function() {
    $('.certs-slider').each(function() {
        var jqContainer = $(this);
        var sliderContainers = jqContainer.find('.certs-slider-holder');
        if (sliderContainers.length <= 0) {
            return false;
        }
        sliderContainers.slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            infinite: false,
            prevArrow: jqContainer.find('.prev').first(),
            nextArrow: jqContainer.find('.next').first(),
            appendDots: jqContainer.find('.nav').first(),
            autoplay: false,
            fade: false,
            speed: 500,
            cssEase: 'linear',
            variableWidth: false,
            responsive: [
                {
                    breakpoint: 1499,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                    }
                }
            ]
        });
    });
});
